<template>
  <div class="preloader preloader-alt no-split">

<span class="spinner spinner-alt"><img class="spinner-brand" src="images/logo-s2-white.png" alt="">
</span>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
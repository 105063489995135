<template>
  <div>
      <div class="modal fade" id="reset-popup">

<div class="modal-dialog modal-dialog-centered">

<div class="modal-content"><a href="#" class="modal-close" data-dismiss="modal" aria-label="Close"><em class="ti ti-close"></em></a>

<div class="ath-container m-0">

<div class="ath-body bg-theme tc-light"><h5 class="ath-heading title">Reset <small class="tc-default">with your Email</small></h5>

<form action="#">

<div class="field-item">

<div class="field-wrap">
<input type="text" class="input-bordered" placeholder="Your Email">
</div>
</div><button class="btn btn-primary btn-block btn-md">Reset Password</button>

<div class="ath-note text-center"> Remembered? <a href="#" data-toggle="modal" data-dismiss="modal" data-target="#login-popup"> <strong>Sign in here</strong></a>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
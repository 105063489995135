<template>
  <div>

    <Nkwrap></Nkwrap>
    <Loginpopup></Loginpopup>
    <Registerpopup></Registerpopup>
    <Resetpopup></Resetpopup>
    <Preloader></Preloader>

  </div>
</template>

<script>
import Nkwrap from './components/Nkwrap.vue';
import Loginpopup from './components/Loginpopup.vue';
import Registerpopup from './components/Registerpopup.vue';
import Resetpopup from './components/Resetpopup.vue';
import Preloader from './components/Preloader.vue';

export default {
  components: {
    Nkwrap,
    Loginpopup,
    Registerpopup,
    Resetpopup,
    Preloader,
  }
}
</script>

<style>

</style>
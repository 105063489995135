<template>
  <div class="nk-wrap" id="nk-wrap">

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></loading>

    <header
      class="nk-header page-header is-transparent is-sticky is-dark"
      id="header"
    >
      <!-- Header @s -->

      <div class="header-main">
        <div class="header-container container container-xxl">
          <div class="header-wrap">
            <!-- Logo @s -->

            <div
              class="header-logo logo animated"
              data-animate="fadeInDown"
              data-delay=".6"
            >
              <a href="/" class="logo-link" v-scroll-to="'#home'">
                <img
                  class="logo-dark"
                  src="images/logo-header.png"
                  alt="logo"
                />
                <img
                  class="logo-light"
                  src="images/logo-header.png"
                  alt="logo"
                />
              </a>
            </div>
            <!-- Menu Toogle @s -->

            <div class="header-nav-toggle">
              <a
                href="#"
                class="navbar-toggle"
                data-menu-toggle="header-menu"
                @click="toggleMobNavbar"
              >
                <div class="toggle-line">
                  <span> </span></div
              ></a>
            </div>
            <!-- Menu @s -->

            <div
              id="header-menu-active-class"
              class="header-navbar animated"
              data-animate="fadeInDown"
              data-delay=".6"
            >
              <nav class="header-menu" id="header-menu">
                <ul class="menu">
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      v-scroll-to="'#home'"
                      >Home</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link"
                      v-scroll-to="'#senior-challenges'"
                      >Senior Challenges</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      href="#"
                      v-scroll-to="'#luxury-ltc-home'"
                      >Luxury LTC Home</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      href="#"
                      v-scroll-to="'#tokenomics'"
                      >Tokenomics</a
                    >
                  </li>
                  <li class="menu-item">
                    <!--<a
                      class="menu-link nav-link menu-toggle"
                      href="#"
                      v-scroll-to="'#nfts'"
                      >NFTs</a
                    >-->
                    <a
                      class="menu-link nav-link "
                      href="https://nozominft.com/" target="_blank"
                      >NFTs</a>
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      href="#"
                      v-scroll-to="'#contact'"
                      >Contact</a
                    >
                  </li>
                  <li class="menu-item">
                    <button
                      class="menu-btn btn btn-round btn-primary"
                      @click="connectMetamask()"
                    >
                      Connect Metamask
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- .header-navbar @e -->
          </div>
        </div>
      </div>
      <!-- .header-main @e -->

      <div class="banner banner-fs tc-light" id="home">
        <div class="nk-block nk-block-sm nk-block-header my-auto">
          <div class="container container-xxl pt-5">
            <div
              class="
                row
                align-items-center
                justify-content-center justify-content-lg-between
                gutter-vr-30px
              "
            >
              <div class="col-lg-6 order-lg-last">
                <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img src="images/azalea/nozomi-banner.gif" alt="header" />
                </div>
              </div>
              <!-- .col -->

              <div class="col-xl-5 col-lg-6 col-sm-10 text-center text-lg-left">
                <div class="banner-caption">
                  <h1
                    class="title title-lg ttu animated"
                    data-animate="fadeInUp"
                    data-delay=".8"
                  >
                    Smart Contracts For Luxury Long Term Living
                  </h1>

                  <h6
                    class="title title-lg ttu animated"
                    data-animate="fadeInUp"
                    data-delay=".8"
                    style="font-size: 1rem; color: #f42f54"
                  >
                    SIMPLIFYING SENIOR LIVING WITH MORE FREEDOM AND CONTROL IN
                    YOUR HANDS
                  </h6>

                  <p
                    class="lead animated"
                    data-animate="fadeInUp"
                    data-delay=".9"
                  >
                    Discover a positive difference for senior living with grace,
                    comfort, and dignity. Have freedom and control of your
                    financial assets and LTC needs with secure blockchain and
                    'smart contracts' enabling safety and simplicity
                  </p>

                  <ul
                    class="
                      btn-grp btn-grp-break
                      justify-content-center justify-content-sm-start
                      gutter-vr-20px
                    "
                  >
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.8"
                    >
                      <a
                        href="https://www.youtube.com/watch?v=ixz_b6d2l1U"
                        class="menu-btn btn btn-round btn-primary video-popup"
                      >
                        <span
                          ><i class="p-1 fas fa-play"></i>How it Works
                        </span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>
        </div>

        <!-- <div class="nk-block nk-block-actions pt-1">
          <div class="container container-xxl">
            <div class="row gutter-vr-40px align-items-center">
              <div
                class="
                  col-sm-7
                  d-flex
                  justify-content-center justify-content-sm-start
                "
              >
                <ul
                  class="
                    btn-grp btn-grp-break
                    justify-content-center justify-content-sm-start
                    gutter-vr-20px
                  "
                >
                  <li class="animated" data-animate="fadeInUp" data-delay="0.8">
                    <a
                      href="https://www.youtube.com/watch?v=ixz_b6d2l1U"
                      class="menu-btn btn btn-round btn-primary video-popup"
                    >
                      <span
                        ><i class="p-1 fas fa-play"></i>How it Works
                      </span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Place Particle Js -->

        <div
          id="particles-bg"
          class="particles-container is-fixed particles-bg op-20"
        ></div>
      </div>
    </header>
    <main class="nk-pages tc-light">
      <!-- // -->

      <section class="section" id="senior-challenges" ref="senior-challenges">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-12">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Face the Reality"
                >
                  CHALLENGES OF SENIOR CARE
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="container container-xxl">
          <div class="nk-block">
            <div class="row text-center align-items-lg-start gutter-vr-40px">
              <div class="col-lg-4 col-sm-6">
                <div
                  class="feature feature-s6 feature-s6-1 animated"
                  data-animate="fadeInUp"
                  data-delay="0.3"
                >
                  <div class="feature-text">
                    <!-- <h5 class="title title-sm ttu">ONE MARKETPLACE</h5> -->

                    <p>
                      As one gets ready to step into the golden years, it is a
                      dawning realization that getting the right kind of care
                      may come at a higher cost. With reducing physical
                      abilities and increased dependency for activities of daily
                      living (ADLs), there is a price to pay for quality care
                      and good LTC facilities.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div
                  class="feature feature-s6 feature-s6-2 animated"
                  data-animate="fadeInUp"
                  data-delay="0.4"
                >
                  <div class="feature-text">
                    <!-- <h5 class="title title-sm ttu">Transparency and Trust</h5> -->

                    <p>
                      Sadly, a lot of people fall woefully short of adequate
                      personal finances to afford long-term care. High long-term
                      care costs can become a big challenge post-retirement for
                      any length of LTC stay. Post-retirement, LTC becomes a big
                      worry for people as the years pass.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div
                  class="feature feature-s6 feature-s6-4 animated"
                  data-animate="fadeInUp"
                  data-delay="0.6"
                >
                  <div class="feature-text">
                    <p>
                      Our LTC Fund addresses this problem and has developed a
                      solution by leveraging blockchain technology and 'Smart
                      Contracts.' The design of these contracts helps Long Term
                      Care receivers get a better alternative with more control
                      and flexibility on their financial assets.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="
                bg-image bg-contain bg-bottom-center bg-ecosystems
                animated
                d-none d-sm-block
              "
              data-animate="fadeInUp"
              data-delay="0.2"
            >
              <img src="images/globe-particle.png" alt="globe" />
            </div>
          </div>
        </div>

        <div class="container container-xxl">
          <div class="nk-block">
            <div class="row text-center align-items-lg-start gutter-vr-40px">
              <div class="col-lg-12 col-sm-12">
                <a
                  href="https://www.youtube.com/watch?v=BJYMoXfgbc4"
                  class="menu-btn btn btn-round btn-primary video-popup"
                >
                  <i class="p-1 fas fa-play"></i>
                  <span>Challenges of Senior Care</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- // -->
      <section class="section" id="platform">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-6">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Statistics"
                >
                  STATISTICS
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="container container-xxl">
          <div class="nk-block">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-8">
                <!-- <ul class="nav tab-nav tab-nav-btn-bdr-s2 justify-content-center justify-content-sm-between pb-4 pb-sm-5 animated" data-animate="fadeInUp" data-delay="0.2">
    <li><a class="active" data-toggle="tab" href="#tab-1-1">FOR Participators</a></li>
    <li class="tab-nav-sap d-none d-sm-block"></li>
    <li><a data-toggle="tab" href="#tab-1-2">For Traders</a></li>
    </ul> -->
              </div>
            </div>

            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab-1-1">
                <div
                  class="
                    row
                    align-items-center
                    justify-content-between
                    gutter-vr-40px
                  "
                >
                  <div class="col-lg-6 order-lg-last">
                    <div
                      class="nk-block-img nk-block-ca animated"
                      data-animate="fadeInUp"
                      data-delay="0.2"
                    >
                      <div class="nk-circle-animation nk-df-center fast"></div>
                      <!-- .circle-animation --><img
                        class="shadow rounded"
                        src="images/app-screens/statistics.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div class="nk-block-text mgb-m30">
                      <!-- <h2 class="title title-md animated" data-animate="fadeInUp" data-delay="0.2">A Open Platform for our Participators</h2> -->

                      <!-- <p class="animated" data-animate="fadeInUp" data-delay="0.2">Each participator can choose the trader and to sign a contract.</p> -->

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.3"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em class="icon icon-md icon-grd ikon ikon-bulb"></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            “By 2030, 24 million Americans will need long-term
                            care (LTC), nearly double the current need, but the
                            supply of caregivers is shrinking rapidly relative
                            to the demand. With medical and healthcare costs
                            spiraling, the costs for Long Term Care for seniors
                            cost is only set to increase.”
                          </p>
                        </div>
                      </div>

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.4"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em
                            class="icon icon-md icon-grd ikon ikon-paricle"
                          ></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            “An estimated $849 billion worth of LTC was provided
                            in 2018; this study estimates that in 2030, LTC
                            costs will more than double and may reach as high as
                            $2.5 trillion, yet plans to pay for this care are
                            woefully insufficient and primarily rely on the
                            continued provision of unpaid care by family
                            members.”
                          </p>
                        </div>
                      </div>

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.5"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em
                            class="icon icon-md icon-grd ikon ikon-bulb-2"
                          ></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            Despite projections of LTC costs in trillions,
                            payment provisions are sadly inadequate, putting
                            pressure on families to take up the financial
                            burden. The lack of flexibility in having LTC funds
                            locked up during the COVID pandemic led to a
                            unfortunate pain and unhappiness with seniors
                            getting locked up and separated from their loved
                            ones.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="tab-1-2">
                <div
                  class="
                    row
                    align-items-center
                    justify-content-between
                    gutter-vr-40px
                  "
                >
                  <div class="col-lg-6 order-lg-last">
                    <div
                      class="nk-block-img nk-block-ca animated"
                      data-animate="fadeInUp"
                      data-delay="0.2"
                    >
                      <div class="nk-circle-animation nk-df-center fast"></div>
                      <!-- .circle-animation --><img
                        class="shadow rounded"
                        src="images/app-screens/sc-medium-a.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div class="nk-block-text mgb-m30">
                      <h2
                        class="title title-md animated"
                        data-animate="fadeInUp"
                        data-delay="0.2"
                      >
                        A Open Platform for our Participators
                      </h2>

                      <p
                        class="animated"
                        data-animate="fadeInUp"
                        data-delay="0.2"
                      >
                        Each participator can choose the trader and to sign a
                        contract.
                      </p>

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.3"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em class="icon icon-md icon-grd ikon ikon-bulb"></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            User have full access to trading consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                          </p>
                        </div>
                      </div>

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.4"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em
                            class="icon icon-md icon-grd ikon ikon-paricle"
                          ></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            Our multi-cryptocurrency exchange will uis aute
                            irure dolor in reprehenderit in voluptate velit
                            uuntur magni dolores esse cillum.
                          </p>
                        </div>
                      </div>

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.5"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em
                            class="icon icon-md icon-grd ikon ikon-bulb-2"
                          ></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            Users will be able to take quis nostrum
                            exercitationem ullam corporis susci pitlabo riosam,
                            nisi ut aliquid ex ea commodie.
                          </p>
                        </div>
                      </div>

                      <div
                        class="feature feature-inline feature-middle animated"
                        data-animate="fadeInUp"
                        data-delay="0.6"
                      >
                        <div class="feature-icon feature-icon-md">
                          <em
                            class="icon icon-md icon-grd ikon ikon-document-2"
                          ></em>
                        </div>

                        <div class="feature-text">
                          <p>
                            Sign a smart-contract to allow our trader’s access
                            for live trading corporis cryptocurrency susci
                            pitlabo riosam, nisi ut.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .container -->
      </section>
      <!-- // -->

      <section class="section" id="luxury-ltc-home">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-12">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Appealing Location"
                >
                  LUXURY LONG TERM LIVING HOME
                </h2>

                <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  Designed for Senior care. We provide an enriching and serene
                  atmosphere with a caring home built for physical and mental
                  wellness. The luxurious green property in North Carolina,
                  charms its way into the hearts of its residents.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container container-xxl">
          <div class="nk-block">
            <div
              class="
                row
                align-items-center
                justify-content-center justify-content-xl-between
                gutter-vr-30px
              "
            >
              <!-- <div class="col-xxl-6 col-xl-6 col-lg-8">

    <div class="nk-block-img nk-block-plx animated" data-animate="fadeInUp" data-delay="0.3"><img class="shadow rounded" src="images/app-screens/sc-medium-b.png" alt=""><img class="nk-block-img-plx plx-screen shadow rounded" src="images/app-screens/sc-small-d.jpg" alt=""><img class="nk-block-img-plx plx-circle plx-circle-s1" src="images/gfx/circle-a.png" alt=""><img class="nk-block-img-plx plx-polygon plx-polygon-s1" src="images/gfx/polygon-a.png" alt=""><img class="nk-block-img-plx plx-triangle plx-triangle-s1" src="images/gfx/triangle-a.png" alt="">
    </div>
    </div> -->

              <div class="col-xxl-12 col-xl-12 col-lg-12">
                <div class="nk-block-text">
                  <div
                    class="feature feature-inline animated"
                    data-animate="fadeInUp"
                    data-delay="0.4"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-xs icon-circle fas fa-check"></em>
                    </div>

                    <div class="feature-text">
                      <p>
                        Just a stone's throw from Down Town Chapel Hill and
                        Durham in charming
                        <a
                          href="https://www.governorsclubnc.com/"
                          class="link2"
                          target="_blank"
                          >Governors Club</a
                        >
                        of North Carolina. A premier custom-home neighborhood in
                        the Research Triangle Park region. Boasting a 27-hole
                        Jack Nicklaus Signature golf course and close to
                        Raleigh-Durham International Airport,
                        <a
                          href="https://www.rtp.org/"
                          class="link2"
                          target="_blank"
                          >Research Triangle Park</a
                        >
                        is known for its renowned universities and world-class
                        healthcare facilities.
                      </p>
                    </div>
                  </div>

                  <div
                    class="feature feature-inline animated"
                    data-animate="fadeInUp"
                    data-delay="0.5"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-xs icon-circle fas fa-check"></em>
                    </div>

                    <div class="feature-text">
                      <p>
                        Active, long-term care residents can enjoy walks on the
                        beautiful American Tobacco Trail. Seniors looking for a
                        peaceful retired life will find the breath-taking beauty
                        surrounding Governors Club worth a tick on the premium
                        location box.
                      </p>
                    </div>
                  </div>

                  <div
                    class="feature feature-inline animated"
                    data-animate="fadeInUp"
                    data-delay="0.6"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-xs icon-circle fas fa-check"></em>
                    </div>

                    <div class="feature-text">
                      <p>
                        North Carolina offers an active lifestyle to learn about
                        varied cultures and contribute to the growth of the
                        senior community and neighborhood as a whole.
                      </p>
                    </div>
                  </div>

                  <div
                    class="feature feature-inline animated"
                    data-animate="fadeInUp"
                    data-delay="0.7"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-xs icon-circle fas fa-check"></em>
                    </div>

                    <div class="feature-text">
                      <p>
                        Our non-profit initiatives help drive our focus on
                        building an enriching quality of life that is more
                        satisfying, engaging, safe, and peaceful.
                      </p>
                    </div>
                  </div>

                  <div
                    class="text-center text-sm-left animated"
                    data-animate="fadeInUp"
                    data-delay="0.9"
                  >
                    <button
                      class="btn btn-round btn-primary"
                      @click="
                        openModal();
                        currentSlide(1);
                      "
                    >
                      <span>VIEW LTC Home Floorplan/Blueprints</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!------World class health care Start--------->
      <section class="section" id="healthcare" style="overflow: hidden">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-12">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="HealthCare"
                >
                  <span class="align-bottoms ml-3 d-inline-block"
                    >World Class HealthCare</span
                  >
                </h2>

                <!-- <p class="animated" data-animate="fadeInUp" data-delay="0.2">Our project is based on an existing cryptocurrency exchange.</p> -->
              </div>
            </div>
          </div>

          <div class="row gutter-vr-50px">
            <div class="col-sm-6 col-lg-2"></div>
            <div class="col-sm-6 col-lg-4 mb-5">
              <div
                class="doc animated fadeInUp"
                data-animate="fadeInUp"
                data-delay="0.4"
                style="visibility: visible; animation-delay: 0.4s"
              >
                <div class="doc-photo doc-shape doc-shape-b">
                  <a
                    href="https://www.dukehealth.org/treatments/home-care-and-hospice"
                    target="_blank"
                  >
                    <img src="images/azalea/DUKE-colored-logo.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 mb-5">
              <div
                class="doc animated fadeInUp"
                data-animate="fadeInUp"
                data-delay="0.5"
                style="visibility: visible; animation-delay: 0.5s"
              >
                <div class="doc-photo doc-shape doc-shape-c">
                  <a
                    href="https://www.uncmedicalcenter.org/uncmc/care-treatment/home-health-care/"
                    target="_blank"
                  >
                    <img src="images/azalea/UNC-colored-logo.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-2"></div>
          </div>
        </div>
      </section>
      <!------World class health care End--------->

      <!-- // -->
      <section class="section" id="tokenomics">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-12">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Long Term Care"
                >
                  How Blockchain Technology Powers
                </h2>

                <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  Blockchain technology is disrupting traditional business
                  models, and here's where our LTC fund leverages the combined
                  power of decentralized finance (Defi) protocols, on-chain
                  asset management, and innovative smart contract technology.
                  Investments will use a decentralized version of ETFs, and
                  fully collateralized baskets of digital assets. The digital
                  asset baskets will be using pooled assets as collateral and
                  held within the relevant smart contract. These smart asset
                  pools can then get tokenized so investors like you can invest
                  funds into the smart contract and acquire shares in the form
                  of stable coins. In addition, the fund is non-custodial,
                  meaning investors remain in control of their deposited assets
                  with complete control and transparency. Connect with us to
                  learn more on this golden opportunity.
                </p>
              </div>
            </div>

            <div class="col-lg-12">
              <a
                href="https://www.youtube.com/watch?v=ZqVWngG4804"
                class="
                  menu-btn
                  btn btn-round btn-primary
                  video-popup
                  ml-3
                  pt-3
                  si
                "
              >
                <span
                  ><i class="p-1 fas fa-play"></i>LTC Investment Fund</span
                ></a
              >
            </div>
          </div>
        </div>

        <div class="container container-xxl">
          <div class="nk-block">
            <div class="row align-items-center gutter-vr-50px">
              <div class="col-xxl col-xl-12 order-last">
                <div class="token-info-s2">
                  <div class="row gutter-vr-50px"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- // -->

      <!--------Start NFT Token Price Section----------->

      <section class="section" id="nfts">
        <!-- Block @s partners logos in money section -->

        <div class="container">
          <div class="nk-block block-partners pb-5 pt-4">
            <ul
              class="has-carousel partner-list justify-content-center animated"
              data-items="5"
              data-items-mobile="2"
              data-auto="true"
              data-loop="true"
              data-animate="fadeInUp"
              data-delay="0.75"
            >
              <li class="">
                <img src="images/azalea/microsoft-colored-logo.png" alt="Microsoft" />
              </li>
              <li class="">
                <img src="images/azalea/ethereum-colored-logo.png" alt="Ethereum" />
              </li>
              <li class="">
                <img src="images/azalea/bitcoin-colored-logo.png" alt="Bitcoin" />
              </li>
              <li class="">
                <img src="images/azalea/transamerica-colored-logo.png" alt="Trans America" />
              </li>
              <li class="">
                <img
                  src="images/azalea/majestic-governors-club-logo.png"
                  alt="Majestic Governors Club"
                />
              </li>
              <li class="">
                <img src="images/azalea/genworth-colored-logo.png" alt="Genworth" />
              </li>
              <li class="">
                <img src="images/azalea/fidelity-colored-logo.png" alt="Fidelity" />
              </li>
              
            </ul>
          </div>
        </div>
        <!-- Block @e partners logos in money section-->
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Token Price"
                >
                  <img src="images/nzm-logo.png" alt="" width="170" />
                  <div
                  class="banner-gfx banner-gfx-auto animated ml-3 d-inline-block"
                  data-animate="fadeInUp"
                  data-delay=".7"
                ><img
                    src="images/azalea/Revised-NOZOMI-FINAL-logo.gif"
                    alt=""
                    width="350"
                  />
                
                </div>
                  
                  <!-- <span class="align-bottoms ml-3 d-inline-block">
                    = $3,723.00<br
                  /></span> -->
                </h2>
              </div>
            </div>
          </div>
        </div>

         <!-- <div class="container">
          <div class="nk-block block-partners pb-5 pt-4">
            <img src="images/nzm-logo.png" alt="" width="170" />
                  
                  <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img src="images/azalea/nozomi-animated-logo-2.gif" alt="header"  />
                </div>

          </div>
         </div> -->
      </section>

      <!-----End NFT Token Price Section------------>

      <section class="section" id="takenmics">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-6">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Timeline"
                >
                  ROADMAP
                </h2>

                <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  Nozomifund is a decentralized platform that helps Seniors with
                  Long-term care needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container container-xxl">
          <div class="nk-block">
            <div
              class="roadmap-wrap roadmap-wrap-ovl-right text-center animated"
              data-animate="fadeInUp"
              data-delay="0.3"
            >
              <div class="roadmap-line-s2"></div>

              <div
                class="roadmap-slider has-carousel carousel-nav-center"
                data-blank="true"
                data-items="5"
                data-items-tab-l="4"
                data-items-tab-p="3"
                data-items-mobile="1"
                data-margin="-1"
                data-navs="true"
              >
                <!-- <div
                  class="
                    roadmap
                    roadmap-s2
                    roadmap-i1
                    roadmap-finished
                    roadmap-runnings
                  "
                >
                  <h6 class="roadmap-year-s2">Q1 2022</h6>
                  <h5 class="title title-sm roadmap-date">Jan - Mar 2022</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Publish Nozomi LTC whitepaper.</li>
                      <li>Launch Nozomi LTC platform.</li>
                    </ul>
                  </div>
                </div>

                <div class="roadmap roadmap-s2 roadmap-i2 roadmap-finished">
                  <h6 class="roadmap-year-s2">Q2 2022</h6>
                  <h5 class="title title-sm roadmap-date">Apr - Jun 2022</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Start Private Token Sale for investors.</li>
                      <li>Token2049 Global Crypto Asia - Singapore.</li>
                    </ul>
                  </div>
                </div> -->

                <div class="roadmap roadmap-s2 roadmap-i3 roadmap-running">
                  <h6 class="roadmap-year-s2">Q3 2022</h6>
                  <h5 class="title title-sm roadmap-date">Jul - Sep 2022</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Launch Nozomi LTC Platform</li>
                      <li>TOKEN 2049 Singapore</li>
                    </ul>
                  </div>
                </div>

                <div class="roadmap roadmap-s2 roadmap-i4">
                  <h6 class="roadmap-year-s2">Q4 2022</h6>
                  <h5 class="title title-sm roadmap-date">Oct - Dec 2022</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Private LTC Fund Open To Select Investors</li>
                    </ul>
                  </div>
                </div>

                <div class="roadmap roadmap-s2 roadmap-i5">
                  <h6 class="roadmap-year-s2">Q1 2023</h6>
                  <h5 class="title title-sm roadmap-date">Jan - Mar 2023</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Finalize LTC Home Architecture Plans</li>
                      <li>Begin Contractor bids for construction</li>
                    </ul>
                  </div>
                </div>

                <div class="roadmap roadmap-s2 roadmap-i6">
                  <h6 class="roadmap-year-s2">Q2 2023</h6>
                  <h5 class="title title-sm roadmap-date">Apr - Jun 2023</h5>

                  <div class="roadmap-text">
                    <p>Investors Meeting - Research Triangle Park, NC</p>
                  </div>
                </div>

                <div class="roadmap roadmap-s2 roadmap-i5">
                  <h6 class="roadmap-year-s2">Q3 2023</h6>
                  <h5 class="title title-sm roadmap-date">Jul - Sep 2023</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Token 2049</li>
                      <li>- London</li>
                    </ul>
                  </div>
                </div>

                <div class="roadmap roadmap-s2 roadmap-i6">
                  <h6 class="roadmap-year-s2">Q4 2023</h6>
                  <h5 class="title title-sm roadmap-date">Oct - Dec 2023</h5>

                  <div class="roadmap-text">
                    <ul>
                      <li>Begin new construction for LTC Home</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- // -->

      <!-- // -->
      <section class="section">
        <div class="container container-xxl">
          <div class="nk-block">
            <div class="row justify-content-between gutter-vr-80px">
              <!-- <div class="col-lg-6">
                <div class="section-head section-head-s2 text-center">
                  <h2
                    class="title animated"
                    data-animate="fadeInUp"
                    data-delay="0.1"
                    title="PARTNERS"
                  >
                  PARTNER SERVICES
                  </h2>
                </div>

                <div class="nk-block">
                  <div
                    class="has-carousel carousel-nav-center pdb-l"
                    data-items="1"
                    data-navs="true"
                  >
                    <div class="item pb-3">
                      <div class="row justify-content-center gutter-vr-30px">
                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.2"
                          >
                            <img
                              src="images/partners/microsoft-partner.png"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.25"
                          >
                            <img
                              src="images/partners/ethereum-partner.png"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.3"
                          >
                            <img
                              src="images/partners/transamerica-partner.png"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.35"
                          >
                            <img
                              src="images/partners/majestic-partner.png"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.4"
                          >
                            <img src="images/partners/unc-partner.png" alt="" />
                          </div>
                        </div>

                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.4"
                          >
                            <img src="images/partners/genworth-partner.png" alt="" />
                          </div>
                        </div>
                        <div class="col-6 col-sm-4">
                          <div
                            class="partner-logo animated"
                            data-animate="fadeInUp"
                            data-delay="0.4"
                          >
                            <img src="images/partners/fidelity-partner.png" alt="" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- .col -->

              <div class="col-lg-2"></div>

              <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="section-head section-head-s2 text-center">
                  <h2
                    class="title animated"
                    data-animate="fadeInUp"
                    data-delay="0.7"
                    title="Update"
                  >
                    NEWS
                  </h2>
                </div>

                <div class="nk-block">
                  <div
                    class="has-carousel carousel-nav-center"
                    data-items="1"
                    data-navs="true"
                  >
                    <div class="item pb-2">
                      <div
                        class="blog blog-s2 animated"
                        data-animate="fadeInUp"
                        data-delay="0.8"
                      >
                        <div class="blog-content blog-content-inline">
                          <div class="blog-text">
                            <h5 class="blog-title title-sm">Token 2049</h5>

                            <div class="blog-location">Singapore/London</div>
                          </div>

                          <div class="blog-action">
                            <a
                              href="https://www.asiacryptoweek.com/"
                              class="link"
                              target="_blank"
                            >
                              <span> Learn More </span>
                              <em
                                class="
                                  link-icon link-icon-sm
                                  fas
                                  fa-angle-right
                                "
                              ></em
                            ></a>
                          </div>
                        </div>

                        <div
                          class="bg-image round"
                          data-overlay="theme"
                          data-opacity="85"
                        >
                          <img src="images/azalea/news-hr-a.jpg" alt="" />
                        </div>
                      </div>

                      <div
                        class="blog blog-s2 animated"
                        data-animate="fadeInUp"
                        data-delay="0.85"
                      >
                        <div class="blog-content blog-content-inline">
                          <div class="blog-text">
                            <h5 class="blog-title title-sm">
                              Future Blockchain Summit
                            </h5>

                            <div class="blog-location">Dubai</div>
                          </div>

                          <div class="blog-action">
                            <a
                              href="https://www.futureblockchainsummit.com/home"
                              class="link"
                              target="_blank"
                            >
                              <span> Learn More </span>
                              <em
                                class="
                                  link-icon link-icon-sm
                                  fas
                                  fa-angle-right
                                "
                              ></em
                            ></a>
                          </div>
                        </div>

                        <div
                          class="bg-image round"
                          data-overlay="theme"
                          data-opacity="85"
                        >
                          <img src="images/azalea/news-hr-b.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- .col -->

              <div class="col-lg-2"></div>
            </div>
            <!-- .row -->
          </div>
          <!-- .nk-block -->
        </div>
        <!-- .container -->
      </section>
      <!-- // -->
      <section class="section" id="faqs">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-6">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="FAQS"
                >
                  FAQS
                </h2>

                <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  If you have any other questions, please get in touch.
                </p>
              </div>
            </div>
          </div>
          <!-- Block @s -->

          <div class="nk-block">
            <div class="row justify-content-center">
              <div class="col-xl-10">
                <!--<ul class="nav tab-nav tab-nav-btn tab-nav-btn-bdr tab-nav-center pdb-r animated" data-animate="fadeInUp" data-delay="0.3">
        <li><a class="active" data-toggle="tab" href="#tab-2-1">General Questions</a></li>
        <li><a data-toggle="tab" href="#tab-2-2">ICO Questions</a></li>
        <li><a data-toggle="tab" href="#tab-2-3">Tokens Sales</a></li>
        <li><a data-toggle="tab" href="#tab-2-4">Investors</a></li>
        </ul>-->

                <div class="tab-content">
                  <div class="tab-pane fade show active" id="tab-2-1">
                    <div class="accordion" id="acc-1">
                      <div
                        class="accordion-item accordion-item-s3 animated"
                        data-animate="fadeInUp"
                        data-delay="0.4"
                      >
                        <h5
                          class="accordion-title accordion-title-sm"
                          data-toggle="collapse"
                          data-target="#acc-1-1"
                        >
                          How do I invest in The Nozomi fund?
                          <span class="accordion-icon"></span>
                        </h5>

                        <div
                          id="acc-1-1"
                          class="collapse show"
                          data-parent="#acc-1"
                        >
                          <div class="accordion-content">
                            <p>
                              Our non-profit fund gives investors secure,
                              transparent options that work best for their LTC
                              goals. Traditional USD centralized Banks, US
                              Digital Asset Banks, decentralized Off Shore
                              Digital Banks available.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item accordion-item-s3 animated"
                        data-animate="fadeInUp"
                        data-delay="0.5"
                      >
                        <h5
                          class="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#acc-1-2"
                        >
                          Is the NZM token tax deductible?
                          <span class="accordion-icon"> </span>
                        </h5>

                        <div id="acc-1-2" class="collapse" data-parent="#acc-1">
                          <div class="accordion-content">
                            <p>
                              Yes Nozomi is a non profit 501(c)(3), are eligible
                              to receive tax-deductible contributions in
                              accordance with Code section 170.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        class="accordion-item accordion-item-s3 animated"
                        data-animate="fadeInUp"
                        data-delay="0.6"
                      >
                        <h5
                          class="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#acc-1-3"
                        >
                          Does Nozomi Home take couples?
                          <span class="accordion-icon"> </span>
                        </h5>

                        <div id="acc-1-3" class="collapse" data-parent="#acc-1">
                          <div class="accordion-content">
                            <p>
                              Yes for Priority Investors only. The home will
                              have 2 LTC suites and a private apartment for live
                              in care giver if needed. The main suite will
                              always be reserved for our Priority investors.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- .block @e -->
        </div>
      </section>

      <!-- // -->
      <section class="section" id="contact">
        <div class="container">
          <!-- Block @s -->

          <div class="nk-block nk-block-about">
            <div
              class="
                row
                justify-content-between
                align-items-center
                gutter-vr-50px
              "
            >
              <div class="col-lg-6">
                <div class="nk-block-text">
                  <div class="nk-block-text-head">
                    <h2
                      class="title title-lg ttu animated"
                      data-animate="fadeInUp"
                      data-delay="0.7"
                    >
                      Contact Us
                    </h2>

                    <p
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.8"
                    >
                      We welcome questions you have for our team. If you wish to
                      get in touch, please fill out the form below. Someone from
                      our team will get back to you shortly.
                    </p>
                  </div>

                  <div >
                    <div class="alert alert-success mb-2" role="alert" v-if="emailSuccess">{{ emailSuccess }} <br></div>
                    <div class="alert alert-danger mb-2" role="alert" v-if="emailError">{{ emailError }} <br></div>
                    
                  </div>

                  <form class="nk-form-submit">
                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="field-item animated"
                          data-animate="fadeInUp"
                          data-delay="0.8"
                        >
                          <label class="field-label ttu">Your Name </label>

                          <div class="field-wrap">
                            <input
                              name="contact-name"
                              placeholder="Introduce yourself"
                              type="text" v-model="name"
                              class="input-bordered required"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div
                          class="field-item animated"
                          data-animate="fadeInUp"
                          data-delay="0.9"
                        >
                          <label class="field-label ttu">Your Email </label>

                          <div class="field-wrap">
                            <input
                              name="contact-email"
                              placeholder="Who do we replay to"
                              type="email" v-model="email"
                              class="input-bordered required email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="field-item animated"
                      data-animate="fadeInUp"
                      data-delay="1.0"
                    >
                      <label class="field-label ttu">Your Message </label>

                      <div class="field-wrap">
                        <textarea
                          name="contact-message" v-model="message"
                          placeholder="Leave your question or comment here"
                          class="input-bordered input-textarea required"
                        >
                        </textarea>
                      </div>
                    </div>
                    <input
                      type="text"
                      class="d-none"
                      name="form-anti-honeypot"
                      value=""
                    />

                    <div class="row">
                      <div
                        class="col-sm-5 text-right animated"
                        data-animate="fadeInUp"
                        data-delay="1.1"
                      >
                        <button type="button" @click="sendEmail" class="btn btn-round btn-primary">
                          SEND
                        </button>
                      </div>

                      <div class="col-sm-7 order-sm-first">
                        <div class="form-results"></div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

              <div class="col-lg-5 text-center order-lg-first">
                <div
                  class="nk-block-contact nk-block-contact-s1 animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                >
                  <ul class="contact-list">
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.2"
                    >
                      <em class="contact-icon fas fa-phone"></em>

                      <div class="contact-text">
                        <a href="tel:9196560676"><span>(919) 656-0676</span></a>
                      </div>
                    </li>
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.3"
                    >
                      <em class="contact-icon fas fa-envelope"></em>

                      <div class="contact-text">
                        <a href="mailto:info@nozomifund.com"
                          ><span>info@nozomifund.com </span></a
                        >
                      </div>
                    </li>
                    
                  </ul>

                  <div
                    class="nk-circle-animation nk-df-center white small"
                  ></div>
                  <!-- .circle-animation -->
                </div>
              </div>
            </div>
          </div>
          <!-- .block @e -->
        </div>
      </section>
    </main>

    <footer class="nk-footer-bar section section-s tc-light">
      <div class="container container-xxl">
        <div class="row gutter-vr-10px">
          <!-- <div class="col-lg-6 order-lg-last text-lg-right">
            <ul class="footer-nav">
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms of sales</a></li>
            </ul>
          </div> -->

          <div class="col-lg-12">
            <div class="copyright-text copyright-text-s2 text-center">
              Copyright © 2022 ALL rights reserved and owned by ai Software.
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="nk-ovm nk-ovm-repeat nk-ovm-fixed shape-i">
      <div class="ovm-line"></div>
    </div>

    <!------Nozomi homes popup------->
    <div id="myModal" class="modal">
      <span class="close cursor" @click="closeModal()">&times;</span>
      <div class="modal-content">
        <div class="mySlides">
          <div class="numbertext">1 / 6</div>
          <img src="/images/azalea/01-nozomi-home.jpg" style="width: 100%" />
        </div>

        <div class="mySlides">
          <div class="numbertext">2 / 6</div>
          <img src="/images/azalea/02-nozomi-home.jpg" style="width: 100%" />
        </div>

        <div class="mySlides">
          <div class="numbertext">3 / 6</div>
          <img src="/images/azalea/03-nozomi-home.jpg" style="width: 100%" />
        </div>

        <div class="mySlides">
          <div class="numbertext">4 / 6</div>
          <img src="/images/azalea/04-nozomi-home.jpg" style="width: 100%" />
        </div>

        <div class="mySlides">
          <div class="numbertext">5 / 6</div>
          <img src="/images/azalea/05-nozomi-home.jpg" style="width: 100%" />
        </div>

        <div class="mySlides">
          <div class="numbertext">6 / 6</div>
          <img src="/images/azalea/06-nozomi-home.jpg" style="width: 100%" />
        </div>

        <a class="prev" @click="plusSlides(-1)">&#10094;</a>
        <a class="next" @click="plusSlides(1)">&#10095;</a>

        <div class="caption-container">
          <p id="caption"></p>
        </div>
        <div class="column-row">
          <div class="column">
            <img
              class="demo cursor"
              src="images/azalea/01-nozomi-home-thumb.jpg"
              style="width: 100%"
              @click="currentSlide(1)"
              alt="Nature and sunrise"
            />
          </div>
          <div class="column">
            <img
              class="demo cursor"
              src="images/azalea/02-nozomi-home-thumb.jpg"
              style="width: 100%"
              @click="currentSlide(2)"
              alt="Snow"
            />
          </div>
          <div class="column">
            <img
              class="demo cursor"
              src="images/azalea/03-nozomi-home-thumb.jpg"
              style="width: 100%"
              @click="currentSlide(3)"
              alt="Mountains and fjords"
            />
          </div>
          <div class="column">
            <img
              class="demo cursor"
              src="images/azalea/04-nozomi-home-thumb.jpg"
              style="width: 100%"
              @click="currentSlide(4)"
              alt="Northern Lights"
            />
          </div>
          <div class="column">
            <img
              class="demo cursor"
              src="images/azalea/05-nozomi-home-thumb.jpg"
              style="width: 100%"
              @click="currentSlide(5)"
              alt="Northern Lights"
            />
          </div>
          <div class="column">
            <img
              class="demo cursor"
              src="images/azalea/06-nozomi-home-thumb.jpg"
              style="width: 100%"
              @click="currentSlide(6)"
              alt="Northern Lights"
            />
          </div>
        </div>
      </div>
    </div>
    <!------End Nozomi homes popup -------->
  </div>
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import api from "../config/api";


var slideIndex = 1;
let ethereum = window.ethereum;

export default {
  components: {
            Loading
        },
  data() {
    return {
      name:"",
      email:"",
      message:"",
      emailSuccess: "",
      emailError: "",
      isActiveMobileMenu: false,
      isLoading: false,
    };
  },


  mounted() {
    this.showSlides(slideIndex);
  },
  methods: {
    openModal() {
      document.getElementById("myModal").style.display = "block";
    },
    closeModal() {
      document.getElementById("myModal").style.display = "none";
    },
    plusSlides(n) {
      this.showSlides((slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((slideIndex = n));
    },

    showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("demo");
      var captionText = document.getElementById("caption");
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
      captionText.innerHTML = dots[slideIndex - 1].alt;
    },
    async connectMetamask() {
      if (typeof ethereum !== "undefined") {
        try {
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          const account = accounts[0];
          alert("Your address is: " + account);
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Please install metamask");
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    toggleMobNavbar() {
      document.body.classList.toggle("menu-shown");
    },
    sendEmail(){

      this.isLoading = true;

      api.post('/contact-us', {
        name:this.name,
        email:this.email,
        full_message:this.message,
      }).then(res => {
        this.name = "";
        this.email = "";
        this.message = "";
        this.isLoading = false;

        this.emailSuccess = res.data.message;
        console.log(res.data.message);


      }).catch(err => {
         console.log(err);
         this.isLoading = false;
         this.emailError = err.data.message;
      })


    },
  },
};
</script>

<style>

.vld-overlay.is-active.is-full-page {
position: fixed !important;
z-index: 9999 !important;
}

.vld-overlay .vld-background {
  background: #ffffff;
  opacity: 0.2;
}

a.link2 {
  color: #ea5152 !important;
}

a.link2:hover {
  color: #97afd5 !important;
}

.logo img {
  height: 60px;
}

@media (min-width: 992px) {
  .logo img {
    height: 75px;
  }
  .header-main {
    padding: 0px 0;
  }
}

body.menu-shown .header-navbar {
  left: 0 !important;
  z-index: 10;
}
</style>
